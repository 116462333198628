<script lang="ts" setup>
import type { TypedSchema } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { storeToRefs } from 'pinia'
import { useCountriesStore } from '~/stores/countries'
import { FetchError } from 'ofetch'

let name = 'signup-form'
const { alert } = useAlert()
const countriesStore = useCountriesStore()
await countriesStore.loadCountries()
await countriesStore.loadRegions()

const { countries, countriesLoading, regions, regionsLoading } =
  storeToRefs(countriesStore)

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    tocLink: string
    inline?: boolean
    light: boolean
    email?: string
    country?: string
    region?: string
    submit?: number
    showCaptchaText?: boolean
  }>(),
  {
    inline: false,
    light: false,
    submit: 0,
    showCaptchaText: true,
  }
)

if (props.inline) {
  name = 'subscription-signup-form'
}

const emit = defineEmits(['valid', 'submit', 'resetForm'])

watch(
  () => props.submit,
  () => {
    onSubmit()
  }
)

const { requiredString, optionalString } = useRules()
const validationSchema: ComputedRef<TypedSchema> = computed(() => {
  return toTypedSchema(
    zod.object({
      fullName: requiredString().refine(
        (val) => {
          const names = ('' + val).split(' ')
          const firstName = names[0]
          const lastName = names[1]
          return (
            requiredString(3).safeParse(firstName).success &&
            requiredString(3).safeParse(lastName).success
          )
        },
        {
          message: t('signup.fullNameError'),
        }
      ),
      email: requiredString().email({
        message: t('base.form.emailNotValid'),
      }),
      phone: optionalString(),
      company: optionalString(),
      title: optionalString(),
      country: requiredString(),
      region: optionalString(),
      password: requiredString(8, t('signup.passwordRequirements')).regex(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{5,}$/,
        t('signup.passwordRequirements')
      ),
      terms: zod.literal(true),
    })
  )
})

const { validate, values, errors, setFieldError, meta, resetForm } = useForm({
  validationSchema,
})

const { value: fullName } = useField<string>('fullName')
const { value: email } = useField<string>('email')
const { value: phone } = useField<string>('phone')
const { value: company } = useField<string>('company')
const { value: title } = useField<string>('title')
const { value: country } = useField<string>('country')
const { value: region } = useField<string>('region')
const { value: password } = useField<string>('password')
const { value: subscribeToNewsletter } = useField<boolean>(
  'subscribeToNewsletter'
)
const { value: terms } = useField<boolean>('terms')

if (props.inline) {
  // form is inline, email, country and region will be passed from outside
  watch(
    () => props.email,
    (val) => {
      email.value = val
    },
    { immediate: true }
  )
  watch(
    () => props.country,
    (val) => {
      country.value = val
    },
    { immediate: true }
  )
  watch(
    () => props.region,
    (val) => {
      if (val === null || val === undefined) {
        region.value = ''
      } else {
        region.value = val
      }
    },
    { immediate: true }
  )
}

const isCountryDividedToRegions: ComputedRef<boolean> = computed(() => {
  const countriesWithRegions = Object.keys(regions.value)
  return countriesWithRegions.includes(country.value as string)
})

const valid: ComputedRef<boolean> = computed(() => {
  if (!meta.value.valid) {
    return false
  }
  if (isCountryDividedToRegions.value) {
    const result = requiredString().safeParse(region.value)
    if (!result.success) {
      setFieldError('region', result.error.formErrors.formErrors[0])
      return false
    }
  }
  return true
})

watch(valid, (val) => {
  emit('valid', val)
})

const toast = useToast()

const { getCaptchaToken } = useVueRecaptcha()

async function onSubmit() {
  // validates form except region
  const valid = await validate()
  if (!valid) {
    return
  }
  // validate region, reactive schema cannot be used
  // schema needs value from form and useField can be used first after useForm which needs schema
  if (isCountryDividedToRegions.value) {
    const result = requiredString().safeParse(region.value)
    if (!result.success) {
      setFieldError('region', result.error.formErrors.formErrors[0])
      return
    }
  }

  // whole form is valid
  const names = ('' + values.fullName).split(' ')
  const formData = JSON.parse(JSON.stringify(values))
  if (!isCountryDividedToRegions.value) {
    delete formData.region
  }

  formData.firstName = names[0] || ' '
  formData.lastName = names[1] || ' '
  formData.position = formData.title
  if (props.inline) {
    formData.subscribeToNewsletter = true
  }

  const recaptchaToken = await getCaptchaToken('signup')
  formData.recaptchaToken = recaptchaToken
  try {
    await createAuthRepository().register(formData)
    resetForm()
    emit('resetForm')
    alert(['signup.success'])
  } catch (error) {
    if (error instanceof FetchError) {
      if (error.statusCode === 409) {
        toast.add({
          title: t('signup.userExists'),
          color: 'red',
        })
      }
    } else {
      toast.add({
        title: t('signup.error'),
        color: 'red',
      })
    }
  }
}
</script>

<template>
  <form
    class="bg-transparent bg-cover bg-no-repeat pt-6 text-black"
    :class="{
      'text-neutral-100': light,
    }"
    @submit.prevent="onSubmit"
  >
    <div
      class="flex flex-col"
      :class="{
        'px-0': inline,
      }"
    >
      <FormFieldInput
        v-model="fullName"
        name="fullMame"
        :parent-name="name"
        property-name="full-name"
        label="signup.fullName"
        :icon="'user'"
        required
        :light="light"
        :error="errors.fullName"
      />
      <FormFieldInput
        v-if="!inline"
        v-model="email"
        name="email"
        :parent-name="name"
        property-name="email"
        label="signup.email"
        :icon="'envelope'"
        required
        :light="light"
        :error="errors.email"
        autocomplete="username"
      />
      <FormFieldInput
        v-model="phone"
        name="phone"
        :parent-name="name"
        property-name="phone"
        label="signup.phone"
        :icon="'mobile-screen'"
        :light="light"
        :error="errors.phone"
      />
      <FormFieldInput
        v-model="company"
        name="company"
        :parent-name="name"
        property-name="company"
        label="signup.company"
        :icon="'building'"
        :error="errors.company"
        :light="light"
      />
      <FormFieldInput
        v-model="title"
        name="title"
        :parent-name="name"
        property-name="title"
        label="signup.jobTitle"
        :icon="'briefcase'"
        :error="errors.title"
        :light="light"
      />
      <FormFieldInput
        v-if="!inline"
        v-model="country"
        name="country"
        :parent-name="name"
        property-name="country"
        type="select"
        label="signup.country"
        :icon="'globe'"
        :items="countries"
        :items-loading="countriesLoading || regionsLoading"
        required
        :error="errors.country"
        :light="light"
      />
      <Transition
        enter-active-class="transition-all duration-500 ease-out"
        leave-active-class="transition-all duration-500 ease-in"
        enter-from-class="translate-y-[-20px] opacity-0"
        leave-to-class="translate-y-[-20px] opacity-0"
        appear
      >
        <FormFieldInput
          v-if="!inline"
          v-show="isCountryDividedToRegions"
          v-model="region"
          name="region"
          :parent-name="name"
          property-name="region"
          type="select"
          label="signup.region"
          :icon="'flag'"
          :items="regions[country]"
          :items-loading="regionsLoading"
          required
          :error="errors.region"
          :light="light"
        />
      </Transition>
      <FormFieldInput
        v-model="password"
        name="password"
        :parent-name="name"
        property-name="password"
        type="password"
        label="signup.password"
        :icon="'lock'"
        :error="errors.password"
        required
        :light="light"
        autocomplete="new-password"
      />
      <FormFieldInput
        v-if="!inline"
        v-model="subscribeToNewsletter"
        name="subscribeToNewsletter"
        :parent-name="name"
        propety-name="subscribeToNewsletter"
        type="checkbox"
        label="signup.subscribeToNewsletter"
        :light="light"
      />
      <FormFieldInput
        v-model="terms"
        name="terms"
        :parent-name="name"
        property-name="terms"
        type="checkbox"
        :light="light"
      >
        <template #label="{ labelFor }">
          <label class="me-3 cursor-pointer" :for="labelFor">
            {{ $t('signup.termsAcceptance') }}
            <template v-if="$te('signup.termsEnd')">
              <a :href="props.tocLink" target="_blank">
                {{ $t('signup.terms') }}
              </a>
              {{ $t('signup.termsEnd') }}.
            </template>
            <template v-else>
              <a :href="props.tocLink" target="_blank">
                {{ $t('signup.terms') }}
              </a>
              .
            </template>
          </label>
        </template>
      </FormFieldInput>
      <UiRecaptchaText v-if="showCaptchaText" class="pl-8" />

      <button
        v-if="!inline"
        class="mt-10 border-2 border-white px-8 py-4 text-xl font-bold disabled:border-neutral-400 disabled:text-neutral-400"
        type="submit"
        :disabled="!valid"
      >
        {{ $t('signup.submit') }}
      </button>
    </div>
  </form>
</template>
